<template lang='pug'>
    div
        v-row.sheet-box.my-5(v-if='documentsList.length == 0')
            v-col.d-flex.align-center.justify-center.pa-0( cols='12')
                v-sheet.d-flex.align-center.justify-center(rounded elevation="2" width='50%' height="60%")
                    h5.ma-0.t-black {{$t('DOCUMENT.NO_DOC')}}
        .case-detail-table.scroll-x.pb-0(v-else)
            .data-area(style='position: relative;')
                v-data-table.height_100.table_in_tab( :headers='documentHeaders' :header-props="{sortIcon: 'mdi mdi-arrow-up'}" show-select item-key='id' v-model="selectedRows" :items='documentsList' :items-per-page='itemPerPage' :page.sync='page' @page-count='pageCount = $event' multi-sort height='100%' hide-default-footer='hide-default-footer' :loading='isLoading' loading-text='loading...' style='padding-bottom: 45px')
                    template(v-slot:item.name='{ item }')
                        .ellipsis
                            a.pl-2.ellipsis(v-if='checkPic(item.document)' :title='item.name' href="#" @click='downloadFile(item.document, item.name)') {{ item.name }}
                            a.pl-2.ellipsis(v-else :title='item.name' href="#" @click='downloadFile(item.document, item.name)') {{ item.name }}
                    template(v-slot:item.description='{ item }')
                        .ellipsis
                            span.pl-2.ellipsis(:title='item.description') {{ item.description }}
                    template(v-slot:item.created_on='{ item }')
                        .ellipsis
                            span.pl-2.ellipsis(:title='item.created_on') {{ item.created_on }}
                    template(v-slot:item.updated_on='{ item }')
                        .ellipsis
                            span.pl-2.ellipsis(:title='item.updated_on') {{ item.updated_on }}
                    template(v-slot:item.active='{ item }')
                        v-btn(icon @click='showEditDocDialog(item)')
                            v-icon(size='18') icon-edit
                        v-btn(:disabled='item.status  == "closed"' icon='icon' @click='onDelete(item)')
                            v-icon(size='18') icon-remove
                v-pagination.py-1.mb-3(v-model='page' :length='pageCount' circle :total-visible="10" style='position: absolute; bottom: 0; width: 100%; height: 40px')
        v-dialog(v-model='messageDialog' width='360' content-class='statusDialog')
            message-dialog(@emitMessageDialog='onEmitMessageDialog' :message='message')
        v-dialog(v-model='editDocDialog' width='500' content-class='statusDialog' persistent @keydown.esc='onEmitEditDialog(true)' v-if='editDocDialog')
            edit-document-dialog(@emitEditDialog='onEmitEditDialog' :docData='editDocument' :class_name='class_name' :target_id='target_id')
        v-dialog(v-model='addDocDialog' width='500' content-class='statusDialog' persistent @keydown.esc='onEmitAddDialog(true)' v-if='addDocDialog')
            add-document-dialog(@emitAddDialog='onEmitAddDialog' :class_name='class_name' :target_id='target_id')
</template>
<script>
import messageDialog from '@/components/Dialog/messageDialog';
import i18n from '/common/plugins/vue-i18n.js';
import DocumentDataService from '@/services/DocumentDataService';
import { fetchStreamingBlobAndDownload } from '@/services/api/fetchStreamingBlobAndDownload';
import addDocumentDialog from '@/components/Dialog/Document/addDocumentDialog';
import editDocumentDialog from '@/components/Dialog/Document/editDocumentDialog';
// import messageDialog from '@/components/Dialog/messageDialog';

export default { 
    props: {
        class_name:{
            type: String,
            require: true,
        },
        target_id:{
            type: String,
            require: true,
        },
        addDocDialog:{
            type: Boolean,
            require: true,
        },
        isDel:{
            type: Boolean,
            require: true,
        },
    },
    components: {
        editDocumentDialog,
        addDocumentDialog,
        messageDialog,
    },
    data() {
        return {
            oringalData:null,
            editDocument:null,
            selectedRows:[],
            message:'',
            msgDialog_mode:'',
            messageDialog:false,
            editDocDialog:false,
            // addDocDialog:false,
            page: 1,
            pageCount: 0,
            itemPerPage: this.itemInPage ? this.itemInPage : 10,
            documentHeaders: [
                {  text: i18n.t('DOCUMENT.NAME'), value: 'name', align: 'center', width: '20%' },
                {  text: i18n.t('DOCUMENT.DESCRIPTION'), value: 'description', align: 'center', width: '20%'},
                {  text: i18n.t('DOCUMENT.CREATE'), value: 'created_on', align: 'center' , width: '20%'},
                {  text: i18n.t('DOCUMENT.UPDATE'), value: 'updated_on', align: 'center' , width: '20%'},
                {  text: i18n.t('GENERAL.ACTIVE'), value: 'active', align: 'center', width: '130'  , sortable: false,},
            ],
            isLoading: true,
            documentsList:[],
            picType:['.jpg','.jpeg','.png','.raw','.dng','.gif','.bmp','.psd','.tiff','.svg','.exif'],
            deleteItem:null,
        }
    },
    async created(){
        await this.get_documnet_list();
        await (this.isLoading = false);
    },
    methods: {
        getDataFromResponse(data){
            let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',hour: '2-digit',minute: '2-digit',hour12: false  };
            let created  = new Date(data.created_on);
            let updated  = new Date(data.updated_on);
            let formatted_created = created.toLocaleDateString("zh-TW", options); 
            let formatted_updated = updated.toLocaleDateString("zh-TW", options); 
            if(this.$i18n.locale != "zh_TW")
            {
                formatted_created = created.toLocaleDateString("en-US", options); 
                formatted_updated = updated.toLocaleDateString("en-US", options); 
            }
            data.created_on = formatted_created;
            data.updated_on = formatted_updated;
            return data
        },
        get_documnet_list(){
            DocumentDataService.list(this.class_name,this.target_id).then((response)=>{
                this.documentsList = response.data;
                this.documentsList.forEach(el => {
                    this.getDataFromResponse(el);
                });
            });
        },
        onEmitAddDialog(val,data){
            if(val){
                this.documentsList.push(data);
            }
            this.$emit('emitDocDialog',false);
        },
        onDelete (item) {
            if(this.isDel){
                this.deleteItem = item;
                this.msgDialog_mode = 'bulk_delete';
                this.showMessageDialog(i18n.t('DOCUMENT.MESSAGE_DEL_2'));
            }else{
                this.deleteItem = item;
                this.msgDialog_mode = 'delete';
                this.showMessageDialog(i18n.t('DOCUMENT.MESSAGE_DEL'));
            }
        },
        showMessageDialog(message) {
            this.messageDialog = true;
            this.message = message;
        },
        onEmitMessageDialog(val) {
            this.messageDialog = false;
            if (val) { 
                // 如果messageDialog是在刪除時被觸發
                if(this.msgDialog_mode == 'delete'){
                    DocumentDataService.delete(this.deleteItem.id).then(()=>{
                    let index =  this.documentsList.indexOf(this.deleteItem);
                    this.documentsList.splice(index, 1);
                    this.msgDialog_mode='';
                    })
                }
                if(this.msgDialog_mode == 'bulk_delete'){
                    let ids = []
                    for(let i in this.selectedRows){
                        ids.push(this.selectedRows[i].id);
                    }
                    DocumentDataService.bulk_delete(ids).then(()=>{
                        for(let item in this.deleteItem)
                        {
                            let index =  this.documentsList.indexOf(this.deleteItem[item]);
                            this.documentsList.splice(index, 1);
                        }
                        this.deleteItem = null;
                        this.msgDialog_mode = '';
                        this.selectedRows = [];
                        this.$emit('emitDocDialog');
                    })
                }
            }else{
                if(this.isDel){
                    this.deleteItem = null;
                    this.msgDialog_mode = '';
                    this.$emit('emitDocDialog');
                }
            }
        },
        showEditDocDialog(data){
            this.editDocDialog = true;
            this.editDocument = data;
            this.oringalData = JSON.parse(JSON.stringify(data));
        },
        onEmitEditDialog(data){
            if(data == true)
            {
                data = this.oringalData;
            }
            if(data)
            {
                let editItem = this.documentsList.find(el=>{ return el == this.editDocument});
                editItem.name = data.name;
                editItem.description = data.description;
                editItem.created_on = data.created_on;
                editItem.updated_on = data.updated_on;
                editItem.document = data.document;
            }
            this.editDocDialog = false;
            this.editDocument = {};
        },
        async downloadFile(blob_url, file_name) {
            fetchStreamingBlobAndDownload(blob_url, file_name)
        },
        checkPic(url){
            for(let i in this.picType){
                if(String(url).includes(this.picType[i])){
                    return true;
                }
            }
        },
    },
    watch:{
        "selectedRows": async function (){
            this.$emit("emitDocumentsList", this.selectedRows);
        },
        "isDel": function(){
            if(this.isDel){
                this.onDelete(this.selectedRows);
            }
        },
    }
}
</script>